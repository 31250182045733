import { render, staticRenderFns } from "./studentStatisticsJournal.vue?vue&type=template&id=76613566"
import script from "./studentStatisticsJournal.vue?vue&type=script&lang=js"
export * from "./studentStatisticsJournal.vue?vue&type=script&lang=js"
import style0 from "./studentStatisticsJournal.vue?vue&type=style&index=0&id=76613566&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports